export const getRequestData = (api, data) => {
  
  //console.log('getRequestData', api)
  return {
    body: data,
    url: apis[api]["url"],
    baseURL:apis[api]["baseURL"] == undefined ? process.env.VUE_APP_URL : process.env["VUE_APP_URL_" + apis[api]["baseURL"]],
    source:apis[api]["baseURL"] == undefined ? "DEFAULT": apis[api]["baseURL"],
  };
};

const apis = {
  userLogin:{
    url: "/v2/lifeterm/login",
    baseURL: "LOG"
  },
  getAttributes:{
    url: "/v2/lifeterm/getAttributes",
    baseURL: "RMD"
  },
  getRules:{
    url: "/v2/lifeterm/getRules",
    baseURL: "RMD"
  },
  addAttribute:{
    url: "/v2/lifeterm/addAttribute",
    baseURL: "RMD"
  },
  saveQuote: {
    url: "/v2/lifeterm/quote",
    baseURL: "RMD"
  },
  saveQuoteResponse: {
    url: "/v2/lifeterm/quoteResponse",
    baseURL: "RMD"
  },
  saveLogData: {
    url: "/v2/lifeterm/logData",
    baseURL: "RMD"
  },
  getLogData: {
    url: "/v2/lifeterm/logdata?mobileNumber=param1&DOB=param2",
    baseURL: "RMD"
  },
  savePaymentData:{
    url: "/v2/lifeterm/savePaymentData",
    baseURL: "RMD"
  },
  saveProposal: {
    url: "/v2/lifeterm/proposalData",
    baseURL: "RMD"
  },
  saveResumeURL:{
    url: "/v2/lifeterm/manufacturerResumeURL",
    baseURL: "RMD"
  },
  getResumeURL: {
    url: "/v2/lifetermmanufacturerResumeURL?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  getProposalDetails: {         
    url: "/v2/lifeterm/ProposalByProposalUiId?proposalUiId=param1",
    baseURL: "RMD"
  },
  getQuoteDetails: {         
    url: "/v2/lifeterm/QuoteDetails?UiQuoteId=param1",
    baseURL: "RMD"
  },
  getQuoteResponseDetails: {         
    url: "/v2/lifeterm/QuoteResponse?UiQuoteId=param1",
    baseURL: "RMD"
  },
  getJourneyId: {
    url: "/v2/journey",
    baseURL: "RMD"
  },
  getToken: {
    url: "/v2/getTokenProxy",
    baseURL: "RMD"
  },
  generateToken:{
    url: "/v2/token?tokenType=SESSION",
    baseURL: "RMD"
  },  
  refreshToken:{
    url: "/v2/token/refresh?tokenType=SESSION",
    baseURL: "RMD"
  },
  getSPDetails: {
    url: "/v2/lifeterm/SPDetailsProxy",
    baseURL: "RMD"
  },
  // getProductInfo: {
  //   url: "/quote/getProductUIData?manufacturerId=param1&productId=param2",
  //   baseURL: "TEST"
  // },
  getPincodeInfo: {
    url: "/v2/lifeterm/zipcode?zipcode=param1",
    baseURL: "RMD"
  },
  getProposal: {
    url: "/v2/lifeterm/proposalApi?productId=param1&manufacturerId=param2&version=param3",
    baseURL: "RMD"
  },
  validateProposal:{
    url: "/v2/lifeterm/proposalValidationProxy",
    baseURL: "RMD"
  },
  // submitProposal: {
  //   url: "proposal?version=1",
  //   baseURL: "PROPOSAL"
  // },
  // getProposalPoll: {
  //   url: "proposal/poll?productId=param1&manufacturerId=param2&requestId=param3",
  //   baseURL: "PROPOSAL"
  // },
  // getReqId: {
  //   url: "/quote"
  // },
  // getLifeTerm: {
  //   url: "/quote/param1"
  // },
  gateCriteria: {
    url: "/v2/lifeterm/gateCriteria?productGroupId=LifeTerm&productId=param1&manufacturerId=param2&version=param3",
    baseURL:"RMD"
  },
  gateDocRequirements: {
    url: "/v2/lifeterm/gateDocRequirements",
    baseUrl: "RMD"
  },
  sendMail: {
    url: "/v2/lifeterm/sendMail",
    baseURL: "RMD"
  },
  ProposalStatus: {
    url: "/v2/lifeterm/ProposalStatus?proposal_ui_id=param1&status_id=param2",
    baseURL: "RMD"
  },
  getProposalStatus: {
    url: "/v2/lifeterm/proposalStatusByProposalUiId?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  getRmDetails: {
    url: "/v2/lifeterm/getRM_Details?id=param1",
    baseURL: "RMD"
  },
  getRmDetailsDIY: {
    url: "/v2/lifeterm/getRM_Details_DIY?id=param1",
    baseURL: "RMD"
  },
  getDashboardData:{
    url: "/v2/lifeterm/getProposalByRm?rmCode=param1&pageSize=param2&pageNumber=param3",
    baseURL: "RMD"
  },
  getDashboardDataFilter:{
    url: "/v2/lifeterm/getProposalByRmFilter?pageSize=param1&pageNumber=param2&columnName=param3&filterText=param4&startDate=param5&endDate=param6",
    baseURL: "RMD"
  },
  getRequirements:{
    url: "/v2/lifeterm/getRequirementsByProposal?proposalId=param1",
    baseURL: "RMD"
  },
  getPaymentDetails:{
    url: "/v2/lifeterm/getPaymentDetail?transactionId=param1",
    baseURL: "RMD"
  },
  getQuoteArka:{
    url: "/v2/grpterm/quote",
    baseURL: "RMD"
  },
  getQuoteArkaPoll:{
    url: "/v2/grpterm/quote/poll?request_id=",
    baseURL: "RMD"
  },
  // sendSMS: {
  //   url: "/sendsms?username=param1&password=param2&to=param3&from=param4&udh=param5&text=param6",
  //   baseURL: "SMS"
  // },
  // getOTP: {
  //   url: "/sendotp",
  //   baseURL: "PROPOSAL"
  // },
  // submitOTP: {
  //   url: "/valotp",
  //   baseURL: "PROPOSAL"
  // },
  uploadDoc: {
    url: "/v2/lifeterm/uploadDoc",
    baseURL: "RMD"
  },
  getWhatsappDetails: {
    url: "/v2/lifeterm/consentMaster",
    baseURL: "RMD"
  },
  saveProposalResponse: {
    url: "/v2/lifeterm/proposalResponse",
    baseURL: "RMD"
  },
  // getPaymentURL: {
  //   url: "/v2/lifeterm/payment/url",
  //   baseURL: "PAYMENT"
  // },
  quotationApiGet: {
    url: "/v2/lifeterm/quotationApi",
    baseURL: "RMD"
  },
  
  proposalApiGet: {
    url: "/v2/lifeterm/proposalApi",
    baseURL: "RMD"
  },
  sendOtpApi: {
    url: "/v2/lifeterm/sendOtpApi",
    baseURL: "RMD"
  },
  valOtpApi: {
    url: "/v2/lifeterm/valOtpApi",
    baseURL: "RMD"
  },
  proposalVersionApi: {
    url: "/v2/lifeterm/proposalVersionApi",
    baseURL: "RMD"
  },
  proposalPollApi: {
    url: "/v2/lifeterm/proposalPollApi?productId=param1&manufacturerId=param2&requestId=param3",
    baseURL: "RMD"
  },
  getProductUiDataApi: {
    url: "/v2/lifeterm/getProductUiDataApi?manufacturerId=param1&productId=param2",
    baseURL: "RMD"
  },
  paymentUrlApi: {
    url: "/v2/lifeterm/paymentUrlApi",
    baseURL: "RMD"
  },
  getLifeTermApi: {
    url: "/v2/lifeterm/getLifeTermApi?requestId=param1",
    baseURL: "RMD"
  },
  downloadReceipt:{
    url: "/v2/lifeterm/downloadReciept",
    baseURL: "RMD"
  }
};